.container {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-top: 30px;
    font-size: 12pt;
    user-select: none;
    margin-left: 20px;
}

.container:last-child {
    padding-bottom: 90px;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    border-radius: 50%;
    border: 1.5px solid #fff; 
    position: absolute;
    top: 6px;
    left: -20px;
    height: 10px;
    width: 10px;
    background: #fff;
    transition: all 0.15s ease-in-out; 
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
}

@media (min-width: 576px) {
    .container {
        margin-top: 50px;
        font-size: 18pt;
    }

    .checkmark {
        width: 10px;
        height: 10px;
        top: 12px;
    }
}

@media (min-width: 768px) {
    .container {
        margin-top: 50px;
        font-size: 21pt;
        padding-left: 20px;
    }

    .checkmark {
        width: 10px;
        height: 10px;
        top: 16px;
    }
}

@media (min-width: 991px) {
    .container {
        margin-top: 70px;
        font-size: 28pt;
        padding-left: 30px;
    }

    .checkmark {
        width: 10px;
        height: 10px;
        top: 22px;
    }
}

@media (min-width: 1200px) {
    .container {
        margin-top: 35px;
        font-size: 16pt;
        padding-left: 20px;
    }

    .checkmark {
        width: 10px;
        height: 10px;
        top: 10px;
    }
}

@media (min-width: 1600px) {
    .container {
        margin-top: 40px;
        font-size: 18pt;
        padding-left: 45px;
    }

    .checkmark {
        width: 10px;
        height: 10px;
        border: 3px solid #fff;
    }
}