@import url("//hello.myfonts.net/count/3ab0b3");

@font-face {
    font-family: 'Acrom-Bold';
    src: url('../fonts/3AB0B3_0_0.eot');
    src: url('../fonts/3AB0B3_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3AB0B3_0_0.woff2') format('woff2'), url('../fonts/3AB0B3_0_0.woff') format('woff'), url('../fonts/3AB0B3_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Acrom-Regular';
    src: url('../fonts/3AB0B3_1_0.eot');
    src: url('../fonts/3AB0B3_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3AB0B3_1_0.woff2') format('woff2'), url('../fonts/3AB0B3_1_0.woff') format('woff'), url('../fonts/3AB0B3_1_0.ttf') format('truetype');
}



/******* COMMON *********/


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

body {
    background: #1c1c1c;
}

p, label, select, input, span {
    font-family: "Acrom-Regular";
    font-weight: normal;
    font-style: normal;
}

h1, button {
    font-family: "Acrom-Bold";
    font-weight: normal;
    font-style: normal;
}

.prev-button {
    font-family: "Acrom-Regular";
}

p, span {
    font-size: 12pt;
    line-height: 18pt;
}

p {
    margin: 15px 0;
}

h1 {
    font-size: 18pt;
    line-height: 21pt;
}

button {
    font-size: 13pt;
}

input:focus {
    outline: none;
}

input, textarea, button, select, label, a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media (min-width: 576px) {
    p, span {
        font-size: 16pt;
        line-height: 24pt;
    }

    p {
        margin: 20px 0;
    }

    h1 {
        font-size: 24pt;
        line-height: 30pt;
    }

    button {
        font-size: 18pt;
    } 
}

@media (min-width: 768px) {
    p, span {
        font-size: 18pt;
        line-height: 28pt;
    }

    p {
        margin: 25px 0;
    }

    h1 {
        font-size: 30pt;
        line-height: 36pt;
    }

    button {
        font-size: 24pt;
    } 
}

@media (min-width: 991px) {
    p, span {
        font-size: 24pt;
        line-height: 36pt;
    }

    p {
        margin: 35px 0;
    }

    h1 {
        font-size: 42pt;
        line-height: 48pt;
    }

    button {
        font-size: 30pt;
    } 
}

@media (min-width: 1200px) {
    p, span {
        font-size: 14pt;
        line-height: 22pt;
    }

    p {
        margin: 25px 0;
    }

    h1 {
        font-size: 30pt;
        line-height: 48pt;
    }

    button {
        font-size: 16pt;
    }
}

@media (min-width: 1600px) {
    p, span {
        font-size: 18pt;
        line-height: 26pt;
    }

    h1 {
        font-size: 36pt;
        line-height: 48pt;
    }

    button {
        font-size: 21pt;
    }
}
/******** END OF COMMON *******/



/******** SLIDES ********/

.App {
    display: flex;
    justify-content: center;
    padding: 110px 30px;
    height: 90vh;
    background: #1C1C1C;
    color: #FFFFFF;
}

.logoWrapper {
    position: fixed;
    width: 120px;
    left: 0px;
    top: 0px;
    padding: 30px;
    background: #1c1c1c;
    width: 100%;
    z-index: 99999;
}

.logoWrapper img {
    width: 120px;
}

.slide-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.button-container {
    position: fixed;
    display: flex;
    bottom: -2px;
    left: 0;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    animation: appearButtons 300ms ease-in-out 1;
}

@keyframes appearButtons {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.button-container > div {
    display: flex;
    align-items: center;
    width: 100%;
}

.next-button, .prev-button {
    display: block;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 20px 0;
}

.prev-button {
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: all 0.5s ease-in-out;
}

.next-button {
    background-color: #fff;
    color: black;
    border-radius: 4px 0 0 0;
    transition: all 0.5s ease-in-out;
    transform: translateX(100%);
}

.short {
    width: 50%;
}

.go {
    transform: translateX(0);
}

.prev-button:focus, .next-button:focus {
    outline: 0;
}

.prev-button {    
    background-color: transparent;
    border: none;
    color: #fff;
    background: #1c1c1c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.again-button {
    background: #1c1c1c;
    color: #fff;
    text-align: center;
    width: 100%;
    font-family: Acrom-Regular;
    margin-top: 15px;
    cursor: pointer;
    outline: none;
}

.prev-button img {
    width: 45px;
    margin-right: 10px;
}

@media (min-width: 576px) {
    .App {
        padding: 130px 50px;
        height: 85vh;
    }

    .logoWrapper img {
        width: 150px;
    }

    .next-button, .prev-button {
        padding: 30px 0;
    }

    .prev-button img {
        width: 65px;
        margin-right: 12px;
    }
}

@media (min-width: 768px) {
    .App {
        padding: 150px 70px;
    }

    .logoWrapper {
        padding: 35px;
    }

    .logoWrapper img {
        width: 170px;
    }

    .next-button, .prev-button {
        padding: 35px 0;
    }

    .prev-button img {
        width: 80px;
        padding-top: 5px;
        margin-right: 15px;
    }

    .again-button {
        font-size: 21pt;
        margin-top: 25px;
    }
}

@media (min-width: 991px) {
    .App {
        padding: 200px 80px;
    }

    .logoWrapper {
        padding: 50px;
    }

    .logoWrapper img {
        width: 220px;
    }

    .next-button, .prev-button {
        padding: 50px 0;
    }

    .prev-button img {
        width: 100px;
        padding-top: 5px;
        margin-right: 18px;
    }

    .again-button {
        font-size: 24pt;
        margin-top: 25px;
    }
}

@media (min-width: 1200px) {
    .App {
        padding: 100px 100px;
        align-items: center;
        height: 100vh;
    }

    .logoWrapper {
        padding: 30px;
    }

    .logoWrapper img {
        width: 150px;
    }

    .next-button, .prev-button {
        padding: 30px 0;
    }

    .prev-button img {
        width: 60px;
        margin-right: 12px;
    }

    .again-button {
        font-size: 24pt;
        margin-top: 0px;
    }
}

@media (min-width: 1600px) {
    .logoWrapper {
        padding: 40px;
    }

    .logoWrapper img {
        width: 150px;
    }

    .next-button, .prev-button {
        padding: 30px 0;
    }

    .prev-button img {
        width: 60px;
        margin-right: 12px;
    }
}
/******** END OF SLIDES ********/




.fadeIn {
    animation: appearSlide 300ms ease-in-out 1;
}

@keyframes appearSlide {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}

/******** FIRST SLIDE ********/

.firstSlide {
    display: flex;
    align-items: center;
    height: 100%;
}

.firstSlide button {
    border-radius: 4px 4px 0 0;
    transform: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.firstSlide .button-container {
    animation: appearFirstButtons 300ms ease-in-out 1;
}

@keyframes appearFirstButtons {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}

@media (min-width: 991px) {
    .firstSlide p {
        width: 700px;
    }
}

@media (min-width: 1200px) {
    .firstSlide {
        text-align: center;
    }

    .firstSlide p {
        width: 520px;
    }

    .firstSlide .button-container {
        position: relative;
    }

    .firstSlide .next-button {
        border-radius: 4px;
        width: unset;
        margin: 10px auto;
        padding: 15px 30px;
        font-size: 14pt;
    }
}

@media (min-width: 1600px) {
    .firstSlide p {
        width: 650px;
    }

    .firstSlide .next-button {
        border-radius: 4px;
        width: unset;
        margin: 10px auto;
        padding: 15px 35px;
        font-size: 18pt;
    }
}
/********* END OF SLIDES*********/


/********* USUAL SIZE SLIDE *****/

.usualSize h1 {
    margin-bottom: 40px;
}

.usualSize input {
    background: #444;
    width: 220px;
    color: #fff;
    border: none;
    border-radius: 4px;
    height: 100px;
    font-size: 48pt;
    font-family: Acrom-Bold;
    padding: 0 20px 0;
}

@media (min-width: 991px) {
    .usualSize input {
        background: #444;
        width: 700px;
        color: #fff;
        border: none;
        border-radius: 4px;
        height: 300px;
        font-size: 170pt;
        font-family: Acrom-Bold;
        padding: 0 40px 0;
    }    
}

@media (min-width: 1200px) {
    
    .usualSize h1 {
        margin-bottom: 25px;
    }

    .usualSize input {
        background: #444;
        width: 500px;
        color: #fff;
        border: none;
        border-radius: 4px;
        height: 200px;
        font-size: 90pt;
        font-family: Acrom-Bold;
        padding: 0 40px 30px;
    }    
}

/********* END OF USUAL SIZE SLIDE *****/



/********* MEASURE SLIDE *****/

.video {
    margin-top: 15px;
}

.measureInputs {
    display: flex;
    padding: 0 0 90px 0;
}

.measureLeft {
    width: 160px;
}

.measureInput {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.inputName {
    margin: 0;
    margin-bottom: 5px;
}

.measureInput input, .measureFourInputs input {
    font-family: Acrom-Bold;
    padding: 0 10px 0;
    margin-right: 10px;
    width: 100px;
    height: 50px;
    background: #444;
    border-radius: 4px;
    border: none;
    font-size: 18pt;
    color: white;
}

.measureFourInputs {
    padding-bottom: 90px;
}

.measureRow {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

@media (min-width: 576px) {
    .video {
        min-height: 220px;
        width: 100%;
        margin-top: 20px;
    }

    .measureInputs {
        padding: 0 0 120px 0;
    }

    .measureLeft {
        width: 240px;
    }

    .measureInput input, .measureFourInputs input {
        padding: 0 15px 0;
        margin-right: 15px;
        width: 150px;
        height: 70px;
        font-size: 28pt;
    }

    .measureFourInputs {
        padding-bottom: 130px;
    }

    .measureRow {
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .video {
        min-height: 240px;
        margin-top: 25px;
    }

    .measureLeft {
        width: 280px;
    }

    .measureInput input, .measureFourInputs input {
        padding: 0 15px 0;
        margin-right: 15px;
        width: 170px;
        height: 80px;
        font-size: 32pt;
    }

    .measureRow {
        margin-top: 25px;
    }

    .measureFourInputs {
        padding-bottom: 160px;
    }
}

@media (min-width: 991px) {
    .video {
        min-height: 350px;
        margin-top: 35px;
    }

    .measureLeft {
        width: 380px;
    }

    .measureInput input, .measureFourInputs input {
        padding: 0 25px 0;
        margin-right: 25px;
        width: 220px;
        height: 120px;
        font-size: 48pt;
    }

    .measureRow {
        margin-top: 35px;
    }

    .measureFourInputs {
        padding-bottom: 220px;
    }
}

@media (min-width: 1200px) {
    .video {
        min-height: 280px;
        width: 550px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .measureWrapper {
        display: flex;
    }

    .measureInfo {
        order: 1;
    }

    .video {
        order: 2;
    }

    .measureText {
        width: 550px;
        margin-right: 50px;
    }

    .measureLeft {
        width: 220px;
    }

    .measureInput input, .measureFourInputs input {
        padding: 0 15px 7px;
        margin-right: 15px;
        width: 120px;
        height: 60px;
        font-size: 24pt;
    }

    .measureInputs {
        padding: 0;
    }

    .measureRow {
        margin-top: 20px;
    }

    .measureFourInputs {
        padding-bottom: 0;
    }
}

@media (min-width: 1600px) {
    .video {
        min-height: 300px;
        width: 550px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .measureText {
        width: 650px;
    }
}
/********* END OF MEASURE SLIDE *****/


/********* QUESTION SLIDE *****/

.questionAnswers {
    display: flex;
    margin-top: 30px;
}

.questionAnswers .next-button {
    border-radius: 4px;
}

.questionAnswers button {
    width: 120px;
    padding: 18px;
    margin-right: 20px;
    border: 4px;
    transform: none;
}

@media (min-width: 576px) {
    .questionAnswers button {
        width: 130px;
        padding: 23px;
        margin-right: 25px;
    }
}

@media (min-width: 768px) {
    .questionAnswers button {
        width: 150px;
        padding: 25px;
        margin-right: 20px;
        border: 4px;
    }
}

@media (min-width: 991px) {
    .questionAnswers {
        margin-top: 35px;
    }

    .questionAnswers button {
        width: 250px;
        padding: 40px;
        margin-right: 40px;
        border: 4px;
    }
}

@media (min-width: 1200px) {
    .questionAnswers {
        margin-top: 35px;
        display: flex;
        justify-content: center;
    }

    .questionAnswers button {
        width: 140px;
        padding: 20px;
        margin-right: 40px;
        border: 4px;
    }
}
/********* END OF QUESION SLIDE *****/





/********* FINAL SLIDE *****/

.resultTextArea {
    height: 95px;
    user-select: none;
    background: #444;
    color: #eee;
    font-family: Acrom-Regular;
    font-size: 12pt;
    border-radius: 4px;
    padding: 10px 15px;
    outline: none;
    width: 100%;
}

.copyButton button {
    border-radius: 4px;
    transform: none;
}

.finalLinks {
    display: flex;
    flex-direction: column;
}

.finalLink {
    color: #fff;
    font-family: Acrom-Bold;
}

@media (min-width: 576px) {
    .resultTextArea {
        height: 125px;
        font-size: 16pt;
        border-radius: 4px;
        padding: 15px 20px;
    }
}

@media (min-width: 768px) {
    .resultTextArea {
        height: 170px;
        font-size: 21pt;
        padding: 20px 35px;
        line-height: 42px;
    }

     .finalLinks {
        flex-direction: row;
    }

    .finalLink {
        margin-right: 50px;
    }
}

@media (min-width: 991px) {
    .resultTextArea {
        height: 230px;
        font-size: 28pt;
        padding: 25px 40px;
        line-height: 54px;
    }
}

@media (min-width: 1200px) {
    .finalText {
        width: 750px;
    }

    .resultTextArea {
        width: 750px;
        height: 145px;
        font-size: 16pt;
        padding: 25px 30px;
        line-height: 30px;
    }

    .copyButton button {
        border-radius: 4px;
        width: 750px;
    }

    .fromStart button {
        font-size: 14pt;
        padding: 15px 0;
    }
}

@media (min-width: 1600px) {
    .resultTextArea {
        font-size: 18pt;
        line-height: 36px;
        height: 170px;
    }
}
/********* END OF FINAL SLIDE *****/


